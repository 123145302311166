import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";

const Form = (props) => {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <div className="">
      {
        rpdata?.simpleWidgets?.[3]?.activo ?
          <form-contact
            to={rpdata?.dbPrincipal?.emails?.[0].email}
            services={rpdata?.dbServices?.slice(0, 6).map((item, service) => {
              return item.name;
            })}
            server={rpdata?.linkPostEmail}
            img={rpdata?.dbPrincipal?.logo}
            emailcolor="1"
            tname="Nombre"
            tlname="Apellido"
            temail="Correo"
            tphone="Teléfono"
            taddress="Dirección"
            ttypeservice="Seleccione El Servicios Que Necesitas"
            tbestway="La Mejor Forma De Contactar Con Usted"
            tcheckphone="Teléfono"
            tcheckcorreo="Correo"
            tmessage="Descripción"
            tbutton="Enviar"
            isenglish="false"
          ></form-contact>
          :
          <form-contact
            to={rpdata?.dbPrincipal?.emails?.[0].email}
            services={rpdata?.dbServices?.slice(0, 6).map((item, service) => {
              return item.name;
            })}
            server={rpdata?.linkPostEmail}
            img={rpdata?.dbPrincipal?.logo}
            emailcolor="1"
            isenglish="true"
          ></form-contact>
      }
    </div>
  );
};

export default Form;

