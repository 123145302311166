import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";
import ServicesHome from "../Services/ServicesHome_2";

function ContentServicesHome() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <>
      <div className="md:w-[98%] mx-auto my-12 text-center">
        <h2 className="text-center py-4 capitalize">
          {
            rpdata?.simpleWidgets?.[3]?.activo ? 'Nuestros servicios' : 'Our Services'
          }

        </h2>
        <p className="px-5 md:px-[20%]">{rpdata?.dbAbout?.[2]?.text}</p>

        <ButtonContent btnStyle="three" btnName={rpdata?.simpleWidgets?.[3]?.activo ? "ver todos los servicios" :"view all services"} />
        <div className="flex flex-wrap items-center justify-center">
          {rpdata?.dbServices?.slice(0, 6).map((item, index) => {
            return (
              <ServicesHome
                key={index}
                bgImg={item.description[0].img}
                serviceName={item.name}
                serviceText={item.description[0].text.substring(0, 160) + "..."}
              />
            );
          })}
        </div>
      </div>
    </>
  );
}

export default ContentServicesHome;
