import React from 'react'



const TraslateHome = ({ cambiarId }) => {

    //ingles
    const id1 = "635aaf2b9a8bcad9ad1b3c8d";
    // español
    const id2 = "63570b7fabe5041b2f51ea71";

    return (
        <div
            className='w-full h-screen relative flex items-center bg-cover bg-left md:bg-center bg-no-repeat before:absolute before:bg-black before:w-full before:h-screen before:opacity-50'
            style={{
                backgroundImage: `url("https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F63570b7fabe5041b2f51ea71%2Fgallery%2FExpress%20Heating%20%26%20Cooling%20LLC-2022-10-24T22%3A19%3A13.308Z-5.jpg?alt=media&token=beca7bfa-8102-445c-bf70-1dc909138145")`
            }}
        >
            <div className='relative flex flex-col items-center w-full'>
                <img
                    src={'https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F63570b7fabe5041b2f51ea71%2FiconAndLogo%2FExpress%20Heating%20%26%20Cooling%20LLC%20-%20WH.png?alt=media&token=8130ae91-892b-4b1b-a4df-fd1b6ce713a7'}
                    alt='no fount'
                    className='w-[80%] md:w-[30%] pb-5'
                />
                <h1 className='text-white capitalize pb-5'>Sitio / site</h1>
                <div className='flex'>
                    <div className='mr-3'>
                        <button
                            onClick={() => cambiarId({ id: id1, l: 'es' })}
                            className='px-5 py-3 rounded-xl bg-[#003C96] text-white'
                        >
                            Español
                        </button>
                    </div>
                    <div className='ml-3'>
                        <button
                            onClick={() => cambiarId({ id: id2, l: 'en' })}
                            className='px-5 py-3 rounded-xl bg-[#FF7D84] text-white'
                        >
                            English
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TraslateHome