import React, { useState } from "react";

import "./index.css";
import "./assets/css/styles.css";
import "./assets/css/slider.css";
import "./assets/css/socialmediaicon.css";

import TraslateHome from "./Page/TraslateHome"
import Generic from "./Page/Generic";

const App = () => {

  const sid= JSON.parse(localStorage.getItem("language"))?? null

  const [language, setLanguage] = useState(sid)
  const [id, setId] = useState("")

  const [isSelectingL, setisSelectingL] = useState(true);



  const cambiarId = (n) => {
    setisSelectingL(!isSelectingL)

    localStorage.setItem("language",JSON.stringify(n))
    setLanguage(JSON.parse(localStorage.getItem("language")))
    console.log(language)
    setId(n.id);
    console.log(isSelectingL)
    console.log(n.id)
  };



  return  language === null ? isSelectingL? <TraslateHome cambiarId={cambiarId}/> : <Generic id={id}/> : <Generic id={language.id}/>
};

export default App;
