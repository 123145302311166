import React, { useContext } from 'react'
import { GlobalDataContext } from '../../../context/context'
import { ButtonContent } from '../boton/ButtonContent'
import { BiPhoneCall } from 'react-icons/bi'
import { BsCalendar4Range, BsClockHistory } from 'react-icons/bs'
import Navmenu from './NavMenu'
import { useLocation } from 'react-router'



const HeaderTwo = ({ cambiarId }) => {
    const { id } = JSON.parse(localStorage.getItem("language"))

    console.log(id)
    //ingles
    const id1 = "635aaf2b9a8bcad9ad1b3c8d";
    // español
    const id2 = "63570b7fabe5041b2f51ea71";

    let location = useLocation();
    console.log(location.pathname)

    const cambiarLang = (obj) => {

        localStorage.setItem("language", JSON.stringify(
            obj

        ))

        if (location.pathname === '/') {
            window.history.pushState({}, null, "/");
            window.location.reload(false)
        } if (location.pathname === '/sobre-nosotros') {
            window.history.pushState({}, null, "/about");
            window.location.reload(false)
        } if (location.pathname === '/about') {
            window.history.pushState({}, null, "/sobre-nosotros");
            window.location.reload(false)
        } if (location.pathname === '/services') {
            window.history.pushState({}, null, "/servicios");
            window.location.reload(false)
        } if (location.pathname === '/servicios') {
            window.history.pushState({}, null, "/services");
            window.location.reload(false)
        } if (location.pathname === '/galeria') {
            window.history.pushState({}, null, "/gallery");
            window.location.reload(false)
        } if (location.pathname === '/gallery') {
            window.history.pushState({}, null, "/galeria");
            window.location.reload(false)
        } if (location.pathname === '/contactenos') {
            window.history.pushState({}, null, "/contact");
            window.location.reload(false)
        } if (location.pathname === '/contact') {
            window.history.pushState({}, null, "/contactenos");
            window.location.reload(false)
        }
        return null


    }

    const { rpdata } = useContext(GlobalDataContext)

    return (
        <header className='absolute right-[3%] left-[3%] z-[15]'>
            <div className='flex'>
                <div className='w-full mr-0 md:w-[35%] lg:w-[25%] lg:mr-10'>
                    <img
                        src={rpdata?.dbPrincipal?.logo}
                        alt='logo'
                        loading='lazy'
                        className='w-[80%] mx-auto pt-3 pb-5'
                    />
                </div>

                <div className='hidden md:block w-[65%] lg:w-[75%] self-center'>
                    <div className='flex'>
                        <div className='w-[55%] lg:w-[75%]'>
                            <div className='grid grid-cols-1 lg:grid-cols-3'>

                                <div className='flex  text-white'>
                                    <div className='w-[15%] self-center mr-3'>
                                        <BiPhoneCall color='white' fontSize={35} />
                                    </div>
                                    <div>
                                        <a
                                            href={`tel:+1${rpdata?.dbPrincipal?.phones?.[0]?.phone}`}
                                        >
                                            <p className='-mb-3'>Phone Number</p>
                                            <h5 className='text-[21px]'>
                                                {rpdata?.dbPrincipal?.phones?.[0]?.phone}
                                            </h5>
                                        </a>
                                    </div>
                                </div>

                                <div className='hidden text-white  lg:flex'>
                                    <div className='w-[15%] self-center mr-3'>
                                        <BsCalendar4Range color='white' fontSize={35} />
                                    </div>
                                    <div>
                                        <p className='-mb-3'>Work Days</p>
                                        <h5 className='text-[21px]'>
                                            {rpdata?.dbPrincipal?.workdays?.[0]?.day}
                                        </h5>
                                    </div>
                                </div>

                                <div className='hidden lg:flex justify-end text-white'>
                                    <div className='w-[15%] self-center mr-3'>
                                        <BsClockHistory color='white' fontSize={35} />
                                    </div>
                                    <div>
                                        <p className='-mb-3'>Work Hours</p>
                                        <h5 className='text-[21px]'>
                                            {rpdata?.dbPrincipal?.workHours?.[0]?.hour}
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='w-[45%] lg:w-[25%] text-end border-l ml-0 lg:ml-7'>
                            <ButtonContent />
                        </div>
                    </div>
                </div>

            </div>

            <div className='bgBloque px-3 py-4'>
                <div className='flex justify-between items-center'>
                    <nav>
                        <Navmenu />
                    </nav>
                    <ul className="flex flex-col md:flex-row pr-0 md:pr-5">
                        <li className='flex justify-center space-x-4 md:space-x-7 pb-5 md:pb-0 pr-5'>
                            {rpdata?.dbSocialMedia?.redes.map((item, index) => {
                                return (
                                    <li key={index} className="text-white">
                                        <a href={item.url} target="_blank" rel='noopener noreferrer'>
                                            <i
                                                className={`fab fa-${item.icon}`}
                                                aria-hidden="true"
                                            />
                                        </a>
                                    </li>
                                );
                            })}
                        </li>
                        <li>
                            <ul className='flex justify-center items-center space-x-3 text-white'>
                                <li>
                                    <button
                                        onClick={() => cambiarLang({ id: id2, l: "en" })}
                                        className='ease-in-out duration-300 hover:text-[red]'
                                    >
                                        {/* <img
                                            src='https://firebasestorage.googleapis.com/v0/b/stockredesdirectorios.appspot.com/o/bandera%20icon%2Fusa-icon.png?alt=media&token=d8803034-9d1c-41b6-8d60-7c355b03dc30'
                                            alt='no fount'
                                            className='w-[20px] md:w-[30px] h-[20px] md:h-[30px]'
                                        /> */}
                                        English
                                    </button>
                                </li>
                                <li>
                                    <button
                                        onClick={() => cambiarLang({ id: id1, l: "es" })}
                                        className='ease-in-out duration-300 hover:text-[red]'
                                    >
                                        {/* <img
                                            src='https://firebasestorage.googleapis.com/v0/b/stockredesdirectorios.appspot.com/o/bandera%20icon%2Fmx-icon.png?alt=media&token=88bad384-5e90-4bc3-a793-93db731cf184'
                                            alt='no fount'
                                            className='w-[20px] md:w-[30px] h-[20px] md:h-[30px]'
                                        /> */}
                                        Español
                                    </button>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </header>
    )
}

export default HeaderTwo