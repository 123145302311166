import React, {useContext} from "react";
import { GlobalDataContext } from "../../context/context";

const TransparentHeader = ({ headertitle, bgimg, Subheader }) => {
  const {rpdata} = useContext(GlobalDataContext)
  return (
    <div
      className="breadcrumb-area"
      style={{ backgroundImage: `url("${bgimg}")` }}
    >
      <div className="text-center relative">
        <h1 className="text-white">{headertitle}</h1>
        {
          headertitle === Subheader ?
            <h6 className="text-white">
            {
              rpdata?.simpleWidgets?.[3]?.activo ?
              <span>Inicio - {Subheader}</span>
              :
              <span>Home - {Subheader}</span>
            }
            
            </h6>
            :
            <h6 className="text-white">{Subheader}</h6>
        }
      </div>
    </div>
  );
};

export default TransparentHeader;
